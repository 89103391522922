import { FC } from 'react';

import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { twMerge } from 'tailwind-merge';

import { AngleDown, EditPencil } from '@assets/icons';
import { ToggleSwitch } from '@components/index';
import { toDateFormat } from '@utils/date';

import { SubscriptionClient } from '../types';

const CHILD_NODE_LEFT_PADDING = 30;

export interface WorkspaceItemProps {
  client: SubscriptionClient;
  clientNodeDepth: number;
  hasClientChildren: boolean;
  hasViewedChildren: boolean;
  onExpandIconClick: () => void;
  onRenameIconClick: () => void;
  onToggleChange: (clientId: number) => void;
}

export interface WorkspaceContainerProps {
  children: React.ReactNode;
  className?: string;
  depth?: number;
}

const WorkspaceContainer: FC<WorkspaceContainerProps> = ({
  children,
  className = '',
  depth,
}) => {
  return (
    <td
      className={twMerge(
        classNames(
          'py-2 h-full align-middle text-[15px] border-r border-gray-6',
          className,
        ),
      )}
      style={{
        paddingLeft:
          isNil(depth) || depth === 0
            ? '20px'
            : `${depth * CHILD_NODE_LEFT_PADDING}px`,
      }}
    >
      {children}
    </td>
  );
};

export const WorkspaceItem: FC<WorkspaceItemProps> = ({
  client,
  clientNodeDepth,
  hasClientChildren,
  hasViewedChildren,
  onExpandIconClick,
  onRenameIconClick,
  onToggleChange,
}) => {
  return (
    <tr
      className={`border-b border-gray-6 ${hasViewedChildren ? 'bg-gray-50' : ''}`}
    >
      <WorkspaceContainer
        className="w-full flex items-center space-x-2 py-[18px]"
        depth={clientNodeDepth}
      >
        <div className="flex w-full items-center">
          {hasClientChildren ? (
            <div
              className="cursor-pointer flex-shrink-0"
              onClick={onExpandIconClick}
            >
              <AngleDown
                className={`mr-4 cursor-pointer ${hasViewedChildren ? '' : '-rotate-90'}`}
              />
            </div>
          ) : (
            <div className="w-9 flex-shrink-0" />
          )}
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <div>{client.name}</div>
              <EditPencil
                className="cursor-pointer w-4 h-4"
                onClick={onRenameIconClick}
              />
            </div>
          </div>
        </div>
      </WorkspaceContainer>
      <WorkspaceContainer className="w-[20%]">
        {client.creator?.name || '-'}
      </WorkspaceContainer>
      <WorkspaceContainer className="w-[20%]">
        {toDateFormat(client.createdAt) || '-'}
      </WorkspaceContainer>
      <WorkspaceContainer className="w-[10%]">
        {client.userCount || '-'}
      </WorkspaceContainer>
      <WorkspaceContainer className="w-[10%]">
        <ToggleSwitch
          isChecked={client.isActive}
          onChange={() => onToggleChange(client.id)}
        />
      </WorkspaceContainer>
    </tr>
  );
};
