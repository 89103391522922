import { isEmpty } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { WorkspaceListResponse } from '../types';

export const useEditWorkspace = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({
      clientId,
      name,
    }: {
      name: string;
      clientId: number;
      onSuccess: () => void;
      onError: () => void;
    }) =>
      client({
        url: END_POINTS.WORKSPACE.EDIT_CLIENT(clientId),
        method: HttpMethod.PATCH,
        data: { name },
      }),
    {
      onError: (error: Error, variables) => {
        if (variables?.onError) {
          variables.onError();
        }
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to rename workspace',
          message: `${error?.message || 'Request failed'}`,
        });
      },
      onSuccess: (_, variables) => {
        showAlert({
          type: AlertType.SUCCESS,
          message: 'Workspace renamed successfully',
        });

        const cache = queryClient.getQueryData<
          InfiniteData<WorkspaceListResponse>
        >(QUERY_KEYS.WORKSPACE.WORKSPACE_LIST);

        if (variables?.onSuccess) {
          variables.onSuccess();
        }

        if (isEmpty(cache?.pages)) {
          return;
        }
        const updatedPages = cache?.pages?.map(page => {
          const updatedItems = page.items.map(item => {
            if (item.id === variables.clientId) {
              return {
                ...item,
                name: variables.name,
              };
            }

            return item;
          });

          return { ...page, items: updatedItems };
        });
        queryClient.setQueryData<InfiniteData<WorkspaceListResponse>>(
          QUERY_KEYS.WORKSPACE.WORKSPACE_LIST,
          {
            ...cache,
            pages: updatedPages,
          },
        );
      },
    },
  );

  return mutation;
};
