export const WORKSPACE_TABLE_HEADERS = [
  { name: 'Workspace Name', classOverride: 'w-[calc(100%-39%)]' },
  { name: 'Creator', classOverride: 'w-[15%]' },
  { name: 'Date Created', classOverride: 'w-[15%]' },
  { name: 'No. of users', classOverride: 'w-[10%]' },
  { name: 'Action', classOverride: 'w-[10%]' },
];

export const WORKSPACE_CONSTANTS = {
  deactivateMessage:
    'Are you sure you want to continue to deactivate this workspace',
  activateMessage:
    'Are you sure you want to continue to activate this workspace',
  Cancel: ' No Cancel',
};
