import { FC, useEffect, useMemo, useState } from 'react';

import { isNil } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, TextEditModal } from '@components/index';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import AddWorkspaceModal from '@pages/select-workspace/components/AddWorkspaceModal';
import { useCreateWorkspace } from '@pages/select-workspace/hooks/use-create-workspace';
import { useSelectWorkspace } from '@pages/select-workspace/hooks/use-select-workspace';
import { CreateWorkspaceData } from '@pages/select-workspace/types';
import RoutesPath from '@routes/constants';
import { loaderState } from '@store/atoms/loader-state';
import { userProfile } from '@store/atoms/user';

import { WorkspaceItem } from './WorkspaceItem';
import { SubscriptionClient, SubscriptionClientIdMap } from '../types';
import { WORKSPACE_TABLE_HEADERS } from '../constants';
import { getAllClientChildren } from '../utils';
import { WorkspaceAction, WorkspaceOperation } from '../enums';
import { WorkspaceActionConfirmationModal } from './WorkspaceActionConfirmationModal';
import { useWorkspaceAction } from '../hooks/use-workspace-action';
import { useEditWorkspace } from '../hooks/use-edit-workspace';

export interface WorkspaceTableProps {
  workspaces: SubscriptionClient[];
}

const WorkspaceTable: FC<WorkspaceTableProps> = ({ workspaces }) => {
  const setBlockUi = useSetRecoilState(loaderState);
  const currentUser = useRecoilValue(userProfile);

  const [workspaceOperation, setWorkspaceOperation] =
    useState<WorkspaceOperation | null>(null);
  const [currentWorkspace, setCurrentWorkspace] =
    useState<SubscriptionClient | null>();
  const [switchingWorkspace, setSwitchingWorkspace] =
    useState<SubscriptionClient | null>(null);
  const [listedWorkspaces, setListedWorkspaces] = useState(
    [] as SubscriptionClient[],
  );

  const listClientIds = useMemo(
    () => workspaces.map(client => client.id),
    [workspaces],
  );

  const { workspaceIdMap, rootLevelWorkspaces } = useMemo(() => {
    const workspaceIdMap: SubscriptionClientIdMap = {};
    const rootLevelWorkspaces: SubscriptionClient[] = [];

    workspaces.forEach(client => {
      const currentDepth =
        (client.clientPath || '').substring(1).split('/').length - 1;

      // Initialize clientIdMap entry for the current client
      workspaceIdMap[client.id] = {
        children: workspaceIdMap[client.id]?.children || [],
        nodeDepth: currentDepth,
      };

      if (isNil(client.parentId) || !listClientIds.includes(client.parentId)) {
        rootLevelWorkspaces.push(client);

        // Override node depth for root-level clients
        workspaceIdMap[client.id].nodeDepth = 0;
      } else {
        // Add the current client as a child of its parent
        if (!workspaceIdMap[client.parentId]) {
          workspaceIdMap[client.parentId] = { children: [], nodeDepth: 0 };
        }
        workspaceIdMap[client.parentId].children.push(client);
      }
    });

    return { workspaceIdMap, rootLevelWorkspaces };
  }, [workspaces, listClientIds]);

  useEffect(() => {
    setListedWorkspaces(rootLevelWorkspaces);
  }, []);

  const { mutateAsync: createWorkspace, isLoading: isCreatingWorkspace } =
    useCreateWorkspace();
  const { mutateAsync: toggleWorkspaceStatus, isLoading: isLoadingAction } =
    useWorkspaceAction();
  useSelectWorkspace({
    userId: currentUser?.id as number,
    clientUuid: switchingWorkspace?.uuid as string,
    enabled: !isNil(currentUser?.id) && !isNil(switchingWorkspace?.uuid),
    onSuccess: () => {
      window.location.href = RoutesPath.BRAND_LIBRARY;
    },
    onError: () => {
      setBlockUi(false);
    },
  });
  const { mutateAsync: renameClient, isLoading: isLoadingRenaming } =
    useEditWorkspace();

  const toggleClientChildrenView = (
    clientId: number,
    clientChildren: SubscriptionClient[],
    clientAllChildrenIds: number[],
    hasViewedChildren: boolean,
  ) => {
    setListedWorkspaces(prevState => {
      const parentIndex = prevState.findIndex(client => client.id === clientId);
      if (parentIndex === -1) return prevState; // Parent not found, no action

      let updatedClients;
      if (hasViewedChildren) {
        // Remove all children of the client
        updatedClients = prevState.filter(
          client => !clientAllChildrenIds.includes(client.id),
        );
      } else {
        // Add children of the client immediately after the parent
        updatedClients = [...prevState];
        updatedClients.splice(parentIndex + 1, 0, ...clientChildren);
      }

      return updatedClients; // Return the updated state
    });
  };

  const onRenameSuccess = (updatedName: string) => {
    setListedWorkspaces(prevState =>
      prevState.map(workspace =>
        workspace.id === currentWorkspace?.id
          ? { ...workspace, name: updatedName }
          : workspace,
      ),
    );
    onCloseModal();
  };

  const onEditName = (updatedName: string) => {
    renameClient({
      name: updatedName,
      clientId: currentWorkspace?.id as number,
      onSuccess: () => onRenameSuccess(updatedName),
      onError: onCloseModal,
    });
  };

  const onCloseModal = () => {
    setWorkspaceOperation(null);
    setCurrentWorkspace(null);
  };

  const onWorkSpaceCreation = async (data: CreateWorkspaceData) => {
    const response = await createWorkspace(data);
    if (response?.id) {
      showAlert({
        message: `${data.name} is created`,
        type: AlertType.SUCCESS,
      });
      setWorkspaceOperation(null);
      setSwitchingWorkspace(response);
    }
  };

  const onActionSuccess = (
    client: SubscriptionClient,
    isActivating: boolean,
  ) => {
    setListedWorkspaces(prevState =>
      prevState.map(workspace =>
        workspace.id === client.id
          ? { ...workspace, isActive: isActivating }
          : workspace,
      ),
    );
    onCloseModal();
  };

  const onRenameClick = (client: SubscriptionClient) => {
    setCurrentWorkspace(client);
    setWorkspaceOperation(WorkspaceOperation.EDIT);
  };

  const updateActivationStatus = async (isActive: boolean) => {
    if (!currentWorkspace) return;
    await toggleWorkspaceStatus({
      clientId: currentWorkspace.id,
      isActive,
      onSuccess: () => onActionSuccess(currentWorkspace, !isActive),
      onError: onCloseModal,
    });
  };

  const onContinueClick = () => {
    const isActive = currentWorkspace?.isActive;
    updateActivationStatus(isActive as boolean);
  };

  const onToggleChange = (client: SubscriptionClient) => {
    setCurrentWorkspace(client);
    setWorkspaceOperation(WorkspaceOperation.ACTIVTE_DEACTIVATE);
  };

  return (
    <div className="flex flex-col px-8 py-6 overflow-auto h-[calc(100vh-122px)]">
      <div className="border border-gray-6 rounded-t-lg px-8 py-6 text-md">
        <div className="flex justify-between items-center">
          <span className="text-gray-3">
            Showing {workspaces.length} workspaces
          </span>
          <Button
            label="Add workspace"
            classOverride="!w-[180px] !font-bold"
            variant="contained"
            onClick={() => setWorkspaceOperation(WorkspaceOperation.ADD)}
          />
        </div>
      </div>
      <div className="border-l border-r border-gray-6">
        <table className="table-fixed w-full border-spacing-4">
          <thead>
            <tr className="border-b border-gray-6">
              {WORKSPACE_TABLE_HEADERS.map(
                (item: { name: string; classOverride: string }) => (
                  <th
                    key={item.name}
                    className={`h-11 px-5 border-r border-b border-gray-6 text-left text-base font-semibold ${item.classOverride}`}
                  >
                    {item.name}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {listedWorkspaces.length > 0 ? (
              listedWorkspaces.map(client => {
                const clientChildren = workspaceIdMap[client.id]?.children;
                // Traverse from the client node to get all children ids of the client
                const { childrenIds: clientAllChildrenIds } =
                  getAllClientChildren(client.id, workspaceIdMap);

                // Determine if the children of clients are being viewed
                const hasViewedChildren = clientAllChildrenIds.some(
                  clientChildId =>
                    listedWorkspaces.some(
                      client => client.id === clientChildId,
                    ),
                );

                return (
                  <WorkspaceItem
                    key={client.id}
                    client={client}
                    clientNodeDepth={workspaceIdMap[client.id]?.nodeDepth || 0}
                    hasClientChildren={clientChildren?.length > 0}
                    hasViewedChildren={hasViewedChildren}
                    onExpandIconClick={() =>
                      toggleClientChildrenView(
                        client.id,
                        clientChildren,
                        clientAllChildrenIds,
                        hasViewedChildren,
                      )
                    }
                    onRenameIconClick={() => onRenameClick(client)}
                    onToggleChange={() => onToggleChange(client)}
                  />
                );
              })
            ) : (
              <tr className="h-16 border-gray-6 text-md border-b font-medium">
                <td colSpan={5} className="text-center py-4">
                  No workspace available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {workspaceOperation === WorkspaceOperation.ADD && (
        <AddWorkspaceModal
          isOpen
          onClose={onCloseModal}
          onWorkspaceCreation={onWorkSpaceCreation}
          isCreatingWorkspace={isCreatingWorkspace}
        />
      )}
      {workspaceOperation === WorkspaceOperation.ACTIVTE_DEACTIVATE && (
        <WorkspaceActionConfirmationModal
          isOpen
          appearance={
            currentWorkspace?.isActive
              ? WorkspaceAction.DEACTIVATE
              : WorkspaceAction.ACTIVATE
          }
          isLoading={isLoadingAction}
          onCancelClick={onCloseModal}
          onContinueClick={onContinueClick}
        />
      )}
      {workspaceOperation === WorkspaceOperation.EDIT && (
        <TextEditModal
          title="Update workspace name"
          isOpen
          onCloseModal={onCloseModal}
          isLoading={isLoadingRenaming}
          value={currentWorkspace?.name as string}
          onButtonClick={onEditName}
          buttonLabel="Update name"
        />
      )}
    </div>
  );
};

export default WorkspaceTable;
