import { isEmpty } from 'lodash';

import {
  SubscriptionClient,
  SubscriptionClientIdMap,
  SubscriptionClientNode,
} from './types';

export const getAllClientChildren = (
  clientId: number,
  clientIdMap: SubscriptionClientIdMap,
) => {
  const clientNode = clientIdMap[clientId];

  const childrenIds: number[] = [];
  const children: SubscriptionClient[] = [];

  const traverseChildren = (node: SubscriptionClientNode) => {
    node.children.forEach(child => {
      childrenIds.push(child.id);
      children.push(child);

      const childNode = clientIdMap[child.id];
      if (!isEmpty(childNode?.children)) {
        traverseChildren(childNode);
      }
    });
  };

  if (!isEmpty(clientNode?.children)) {
    traverseChildren(clientNode);
  }

  return { childrenIds, children };
};
