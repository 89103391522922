export enum WorkspaceAction {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export enum WorkspaceOperation {
  ADD = 'ADD',
  EDIT = 'EDIT',
  ACTIVTE_DEACTIVATE = 'ACTIVTE_DEACTIVATE',
}
